import TaskService from '@/services/TaskService';
import ClaimService from '@/services/ClaimService';

export const state = {
    actions: {},
    details: {},
    detailsList: {},
    list: [],
    summary: [],
};

/*
    Actions hook up to the interface, but they do not touch the store data directly.
    They can be rather complicated and will generally lean on one or more mutations.
    Actions are also the communication layer with external sources (API).

    Actions come with access to four contexts ({ state, getters, commit, dispatch })
        state = direct data access... avoid use
        getters = indirect data access... this is good
        commit = call a mutation
        dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {
    async setTaskAction({ commit }, { TaskId, action }) {
        await commit('SET_ACTION', { TaskId, action });
    },
    async saveTaskAction({ dispatch }, { Action, Detail, TaskList }) {
        await TaskService.action({ Action, Detail, TaskList }, dispatch);
    },
    async retrieveTaskDetailsList({ commit, dispatch }, { ClaimId, TaskLabel }) {
        await TaskService.detailsList({ ClaimId, TaskLabel }, dispatch).then((response) => {
            const { AvailableActionList, TaskDetailList } = response.data;
            var promises = [];
            TaskDetailList.filter(taskDetail => taskDetail.Detail.ApprovalType == "Letter").forEach(letterApproval => {
                promises.push(ClaimService.retrieveCorrespondence(letterApproval.Detail.LinkReference).then(function (response) {
                    letterApproval.Detail.Body = response.data.Body.replace(/\\n/g, '').replace(/\\"/g, '"');
                    letterApproval.Detail.EmailStyleMarkup = response.data.EmailStyleMarkup.replace(/\\n/g, '');
                    letterApproval.Detail.LetterType = response.data.LetterType;
                    letterApproval.Detail.SaveDate = response.data.SaveDate;
                    letterApproval.Detail.Channel = response.data.Channel;
                    letterApproval.Detail.From = response.data.From;
                    letterApproval.Detail.To = response.data.To;
                    letterApproval.Detail.Subject = response.data.Subject;
                    letterApproval.Detail.TransmissionStatus = response.data.TransmissionStatus;

                }));
            });
            Promise.all(promises).then(function () {
                commit('SET_TASK_DETAILS_LIST', { ClaimId, TaskLabel, AvailableActionList, TaskDetailList });
            })
        }).catch(() => {
            commit('SET_TASK_DETAILS_LIST', { ClaimId, TaskLabel, AvailableActionList: [], TaskDetailList: [] });
        });
    },
    async retrieveTaskList({ commit, dispatch }, {
        ClientId,
        PageNum,
        PageSize,
        SortBy,
        SortType,
        TaskLabel,
    }) {
        await TaskService.retrieveList({
            ClientId,
            PageNum,
            PageSize,
            SortBy,
            SortType,
            TaskLabel,
        }, dispatch).then((response) => {
            commit('SET_TASK_LIST', { label: TaskLabel, list: response.data.TaskList });
        }).catch(() => {
            commit('SET_TASK_LIST', []);
        });
    },
    async retrieveTaskSummary({ commit, dispatch }, { ClientId }) {
        if (ClientId && ClientId !== '') {
            await TaskService.retrieveSummary({ ClientId }, dispatch).then((response) => {
                commit('SET_TASK_SUMMARY', response.data.TaskSummaryList);
            }).catch(() => {
                commit('SET_TASK_SUMMARY', {});
            });
        }
    },
    async setTasksDetail({ commit }, { ClaimId, key, value, action }) {
        await commit('SET_TASKS_DETAIL', { ClaimId, key, value, action });
    },
    async clearTasksDetail({ commit }) {
        await commit('CLEAR_TASKS_DETAIL');
    },
};

/*
    Mutations are methods that act on the state data directly.
    They should be incredibly basic and perform a single task.

    Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
    SET_ACTION(state, { TaskId, action }) {
        state.actions = {
            ...state.actions,
            [TaskId]: action,
        }
    },
    SET_TASK_DETAILS_LIST(state, { ClaimId, TaskLabel, AvailableActionList, TaskDetailList }) {
        const currentClaimData = state.detailsList[ClaimId] || {};
        const currentTaskLabelData = currentClaimData[TaskLabel] || {};

        const mergedTaskLabelData = Object.assign({}, currentTaskLabelData, { AvailableActionList, TaskDetailList });
        const mergedClaimData = Object.assign({}, currentClaimData, { [TaskLabel]: mergedTaskLabelData });

        state.detailsList = Object.assign({}, state.detailsList, { [ClaimId]: mergedClaimData });
    },
    SET_TASK_LIST(state, { label, list }) {
        state.list = Object.assign({}, state.list, { [label]: list });
    },
    SET_TASK_SUMMARY(state, summary) {
        state.summary = summary;
    },
    SET_TASKS_DETAIL(state, { ClaimId, key, value, action }) {
        const currentActionData = state.details[ClaimId] && state.details[ClaimId][action] || {};
        const actionData = {
            ...currentActionData,
            [key]: value,
        }

        const claimDetails = {
            ...state.details[ClaimId]  || {},
            [action]: actionData,
        }

        state.details = {
            ...state.details,
            [ClaimId]: claimDetails,
        }
    },
    CLEAR_TASKS_DETAIL(state) {
        state.details = {};
    },
};

/*
    Getters are non-cached access points to the state data.
    They allow us to interrogate the data and pull back dynamic subsets.
*/

export const getters = {
    getTaskActions: state => { return state.actions },
    getTaskDetails: state => { return state.details },
    getTaskDetailsList: state => { return state.detailsList },
    getTaskList: state => { return state.list },
    getTaskSummary: state => { return state.summary },
};
/*
	The store manages all application data.
	While this data is sourced from our API,
	we may have reason to manipulate it locally in order to...
		initiate a new request
		cleanup and format displays
		sanitize and map server communication
*/

// PRE-Auth
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import * as account from '@/store/modules/account';
import * as api from '@/store/modules/api.js';
import * as application from '@/store/modules/application.js';
import * as claim from '@/store/modules/claim.js';
import * as configuration from '@/store/modules/configuration';
import * as customer from '@/store/modules/customer';
import * as dashboard from '@/store/modules/dashboard.js'
import * as intakeNavigation from '@/store/modules/intake-navigation.js'
import * as notifications from '@/qux-common/store/modules/notifications.js';
import * as task from '@/store/modules/task';

Vue.use(Vuex);

const vuexSessionStorage = new VuexPersist({
	key: 'vuex', // The key to store the state on in the storage provider.
	storage: window.sessionStorage, // or window.localStorage or localForage
	reducer: state => ({
		account: {
			activeClientId: state.account.activeClientId,
			activeTenantId: state.account.activeTenantId,
		},
		application: {
			navList: state.application.navList,
		},
		claim: {
			selectedClaim: state.claim.selectedClaim,
			intake: {
				selectedCustomer: state.claim.intake.selectedCustomer,
				selectedTransactions: state.claim.intake.selectedTransactions,
			},
		},
		dashboard: {
			search: state.dashboard.search,
			daysToLookBack: state.dashboard.daysToLookBack,
			searchType: state.dashboard.searchType,
		},
		intakeNavigation: {
			step: state.intakeNavigation.step,
		},
	})
  })

// State is simply the local data.
export default new Vuex.Store({
	modules: {
		account,
		api,
		application,
		claim,
		configuration,
		customer,
		dashboard,
		intakeNavigation,
		notifications,
		task,
	},
	plugins: [vuexSessionStorage.plugin]
});

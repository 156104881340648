
/**
 * ClaimService.js contains all of the service functions used for claims.
 *
 * @endpoints /claim/
 * 	list - retrieve the list of claims
 *  retrieve - retrieve a specific claim
 *
 * @author Sophia Frankel <sophia.frankel@quavo.com>
 */
import store from '@/store/store.js';
import API from '@/services/QFDAPIService';

export default {
	name: 'ClaimService',
	/**
	 * Retrieve the list of claims
	 * @param {Object} params
	 * @param {Number} params.daysToLookBack - number of days for retrieving claims since today
	 * @param {String} params.searchType - field to search on
	 * @param {String} params.search - data to search for
	 * @returns an object containing an array of claims
	 */
	retrieveClaimList({ daysToLookBack, searchType, search }) {
		return API.call('/claim/list', {
			// conditionally add this to API params as long as it's defined
			...(daysToLookBack ? { daysToLookBack } : {}),
			[searchType]: search,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving claim list. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},
	/**
	 * Retrieve a specific claim
	 * @param {String} claimId - id to load
	 * @returns an object containing claim data
	 */
	retrieveClaim(claimId) {
		return API.call('/claim/retrieve', {
			claimId,
			statusRole: "Customer"
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},
	/**
	 * Add a note to a claim
	 * @param {String} ClaimId - id of claim
	 * @param {String} Subject - note subject
	 * @param {String} Note - note message
	 * @returns an object containing status of API call
	 */
	addNote({ ClaimId, Subject, Note }) {
		return API.call('/claim/notes/add', {
			ClaimId, Subject, Note
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding note to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	sendMessage(ClaimId, Subject, Note) {
		return API.call('/claim/notes/add', {
			ClaimId,
			Subject,
			Note,
			Visibility: "Customer"
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error sending message. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},
	/**
	 * Add attachments to a claim
	 * @param {String} ClaimId - id of claim
	 * @param {Array} AttachmentList - attachment data
	 * @returns an object containing status of API call
	 */
	addAttachments({ ClaimId, AttachmentList }) {
		return API.call('/claim/attachments/add', {
			ClaimId, AttachmentList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding attachment to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * List attachments of a claim
	 * @param {String} ClaimId - id of claim
	 * @returns an array containing the attachments list
	 */
	listAttachments({ ClaimId }) {
		return API.call('/claim/attachments/list', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving attachment list. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Retrieve attachment
	 * @param {String} LinkReference - id of attachment
	 */
	retrieveAttachment(LinkReference) {
		return API.call('/claim/attachments/retrieve', {
			LinkReference
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving attachment. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Add required documents to a claim
	 * @param {String} ClaimId - id of claim
	 * @param {Array} DocumentRequirementList - attachment data
	 * @returns an object containing status of API call
	 */
	addDocumentsToSubmittedClaim({ ClaimId, DocumentRequirementList }) {
		return API.call('/claim/documents/add', {
			ClaimId, DocumentRequirementList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding documents to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},


	/**
	 * Retrieve customers
	 * @param {Number} AccountNumber - account id
	 * @param {Number} CardNumber - card number
	 * @param {Number} CustomerId - customer id
	 * @param {Number} SSNTIN - SSN / TIN
	 * @returns an object containing status of API call
	 */
	retrieveCustomers({ AccountNumber, Address, CardNumber, ClientId, CustomerId, Name, SSNTIN }) {
		return API.call('/claim/intake/retrieveCustomers', {
			AccountNumber, Address, CardNumber, ClientId, CustomerId, Name, SSNTIN
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving customers. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Retrieve accounts
	 * @param {Number} CustomerId - customer id
	 * @returns an object containing status of API call
	 */
	retrieveAccounts({ CustomerId }) {
		return API.call('/claim/intake/retrieveAccounts', {
			CustomerId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving accounts. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Retrieve transactions list
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveTransactionsList({ ClaimId }) {
		return API.call('/claim/transactions/list', {
			ClaimId,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving transaction list. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Retrieve transactions details
	 * @param {String} ClaimId - claim id
	 * @param {String} DisputeId - dispute id
	 * @returns an object containing status of API call
	 */
	retrieveTransactionDetails({ ClaimId, DisputeId }) {
		return API.call('/claim/transactions/details/retrieve', {
			ClaimId, DisputeId,
		}).catch(error => {
			const apiError = error.response.data.StatusList[0].Message;
			const message = apiError || 'A ' + error.message + ' has occurred. Please refresh the page or try again later.';
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message,
			});
		});
	},

	/**
	 * Create a new claim
	 * @param {Number} AccountNumber - account id
	 * @param {Number} CustomerId - customer id
	 * @returns an object containing status of API call
	 */
	createClaim({ AccountNumber, CustomerId }) {
		return API.call('/claim/intake/create', {
			AccountNumber, CustomerId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error creating claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Get the reason questions for a new claim
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveReasons({ ClaimId }) {
		return API.call('/claim/intake/retrieveReasons', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving questionnaire. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Add reasons to claim
	 * @param {Number} ClaimId - claim id
	 * @param {Object} Questionnaire - results of the questionnaire 
	 * @returns an object containing status of API call
	 */
	addClaimReasons({ ClaimId, Questionnaire }) {
		return API.call('/claim/intake/addClaimReasons', {
			ClaimId, Questionnaire
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error submitting questionnaire. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Retrieve claim transactions
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveTransactions({ ClaimId, SearchStartDate, SearchEndDate, SearchAmount }) {
		return API.call('/claim/intake/retrieveTransactions', {
			ClaimId, SearchStartDate, SearchEndDate, SearchAmount
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving transaction list. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Retrieve similar claim transactions
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveSimilarTransactions({ ClaimId, SearchAccountNumber, SearchStartDate, SearchEndDate }) {
		return API.call('/claim/intake/retrieveSimilarTransactions', {
			ClaimId, SearchAccountNumber, SearchStartDate, SearchEndDate
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving transaction list. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Add transactions to claim
	 * @param {Number} ClaimId - claim id
	 * @param {Array} TransactionList - list of transactions to add
	 * @returns an object containing status of API call
	 */
	addClaimTransactions({ ClaimId, TransactionList }) {
		return API.call('/claim/intake/addTransactions', {
			ClaimId, TransactionList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding transactions to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Add similiar transactions to claim
	 * @param {Number} ClaimId - claim id
	 * @param {Array} TransactionList - list of transactions to add
	 * @returns an object containing status of API call
	 */
	addSimilarTransactions({ ClaimId, TransactionList }) {
		return API.call('/claim/intake/addSimilarTransactions', {
			ClaimId, TransactionList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding transactions to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Add recognized transactions to claim
	 * @param {Number} ClaimId - claim id
	 * @param {Array} TransactionList - list of transactions to add
	 * @returns an object containing status of API call
	 */
	recognizeDisputedTransactions({ ClaimId, TransactionList }) {
		return API.call('/claim/intake/recognizeDisputedTransactions', {
			ClaimId, TransactionList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding transactions to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Add check deposits to claim
	 * @param {Number} ClaimId - claim id
	 * @param {Array} CheckDepositList - list of deposits to add
	 * @returns an object containing status of API call
	 */
	addCheckDeposits({ ClaimId, CheckDepositList }) {
		return API.call('/claim/intake/addCheckDeposits', {
			ClaimId, CheckDepositList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding transactions to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Retrieve intake questionnaire
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveQuestionnaire({ ClaimId }) {
		return API.call('/claim/intake/retrieveQuestionnaire', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving questionnaire. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Retrieve other accounts
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveOtherAccounts({ ClaimId }) {
		return API.call('/claim/intake/retrieveOtherAccounts', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving accounts. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Add questionnaire answers to claim
	 * @param {Number} ClaimId - claim id
	 * @param {Object} Questionnaire - results of the intake questionnaire 
	 * @returns an object containing status of API call
	 */
	addQuestionnaire({ ClaimId, Questionnaire }) {
		return API.call('/claim/intake/addQuestionnaire', {
			ClaimId, Questionnaire
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error submitting questionnaire. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Retrieve document requirements
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveDocRequirements({ ClaimId }) {
		return API.call('/claim/intake/retrieveDocRequirements', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving document requirements. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Add documents to claim
	 * @param {Number} ClaimId - claim id
	 * @param {Object} DocumentRequirementList - details of the files to be uploaded
	 * @returns an object containing status of API call
	 */
	addDocuments({ ClaimId, DocumentRequirementList }) {
		return API.call('/claim/intake/addDocuments', {
			ClaimId, DocumentRequirementList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error adding documents to claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Retrieve intake summary
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveSummary({ ClaimId }) {
		return API.call('/claim/intake/retrieveSummary', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving claim summary. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Submit intake
	 * @param {Number} ClaimId - claim id
	 * @param {Object} Questionnaire - results of the questionnaire
	 * @param {Object} CorrespondenceDetails object containing contact information
	 * @returns an object containing status of API call
	 */
	intakeSubmit({ ClaimId, Questionnaire, CorrespondenceDetails }) {
		return API.call('/claim/intake/submit', {
			ClaimId, Questionnaire, CorrespondenceDetails
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error submitting claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
			throw(error);
		});
	},

	/**
	 * Retrieve intake confirmation
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing status of API call
	 */
	retrieveConfirmation({ ClaimId }) {
		return API.call('/claim/intake/retrieveConfirmation', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving claim confirmation. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Request withdrawing a claim
	 * @param {String} ClaimId - id of claim
	 * @param {String} WithdrawalReason - withdrawal reason
	 * @returns an object containing status of API call
	 */
	requestWithdraw({ ClaimId, WithdrawalReason, TransactionList }) {
		return API.call('/claim/withdrawRequest', {
			ClaimId, WithdrawalReason, TransactionList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error requesting claim withdrawal. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Cancel an intake claim
	 * @param {String} ClaimId - id of claim
	 * @param {String} CancelClaimReason - cancel reason
	 * @returns an object containing status of API call
	 */
	cancelIntake({ ClaimId, CancelClaimReason }) {
		return API.call('/claim/intake/cancel', {
			ClaimId, CancelClaimReason
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error canceling the claim. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},


	/**
	 * Request to reopen dispute
	 * @param {String} ClaimId - id of claim
	 * @param {String} ReopenReason - reopen reason
	 * @param {String} ReopenNewInformation - new information to provide
	 * @param {Array} TransactionList - list of transactions affected
	 * @returns an object containing status of API call
	 */
	reopenRequest({ ClaimId, ReopenReason, ReopenNewInformation, TransactionList }) {
		return API.call('/claim/reopenRequest', {
			ClaimId, ReopenReason, ReopenNewInformation, TransactionList
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error requesting claim reopen. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		});
	},

	/**
	 * Retrieve the list of correspondence
	 * @param {Number} ClaimId - id of claim to lookup
	 * @returns an object containing an array of correspondence
	 */
	retrieveCorrespondenceList(ClaimId) {
		return API.call('/claim/correspondence/list', {
			ClaimId,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving correspondence list. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},
	/**
	 * Retrieve a specific correspondence
	 * @param {String} LinkReference - id of correspondence
	 * @returns an object containing correspondence data
	 */
	retrieveCorrespondence(LinkReference) {
		return API.call('/claim/correspondence/retrieve', {
			LinkReference,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving correspondence. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},
	/**
	 * Retrieve info on where to send correspondence
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing correspondence channel data
	 */
	retrieveCorrespondenceChannels({ ClaimId }) {
		return API.call('/claim/intake/retrieveCorrespondenceChannels', {
			ClaimId
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving correspondence channels. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},
	retrieveNote(LinkReference){
		return API.call('/claim/notes/retrieve', {
			LinkReference,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving note. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},
	/**
	 * Retrieve the investigation details
	 * @param {Number} ClaimId - id of claim to lookup
	 * @returns an object containing an array of investigation details
	 */
	retrieveInvestigationDetails(ClaimId) {
		return API.call('/claim/investigationDetails/retrieve', {
			ClaimId,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving investigation details. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},
	/**
	 * Retrieve the list of notes
	 * @param {Number} ClaimId - id of claim to lookup
	 * @returns an object containing an array of notes
	 */
	retrieveNotesList(ClaimId) {
		return API.call('/claim/notes/list', {
			ClaimId,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving notes list. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},

	/**
	 * Retrieve recovery details for a dispute
	 * @param {Number} DisputeId - dispute id
	 * @returns an object containing list of recovery details
	 */
	retrieveRecoveryDetails({ DisputeId }) {
		return API.call('/claim/recoveryDetails/retrieve', {
			DisputeId,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving recovery details. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},

	/**
	 * Retrieve claim details for claim
	 * @param {Number} ClaimId - claim id
	 * @returns an object containing stored questionnaire for claim
	 */
	retrieveClaimDetails(ClaimId) {
		return API.call('/claim/details/retrieve', {
			ClaimId,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving claim details. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},

	retrieveAppliedCredits({DisputeId}) {
		return API.call('/claim/appliedCredits/retrieve', {
			DisputeId,
		}).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if (error.response.data.StatusList) {
					message = "Error retrieving applied credits. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
		})
	},
};